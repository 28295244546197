<template>
  <div v-loading="loading" element-loading-text="正在生成excel，请稍后...">
    <el-breadcrumb class="Bdh" separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>退款管理</el-breadcrumb-item>
      <el-breadcrumb-item>客服诊金申请退款列表</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="add">
      <!-- <p style="float: left;margin: 0;">选择状态：</p> -->
      <el-select
        v-model="select2"
        placeholder="请选择订单状态"
        style="width: 130px; margin-right: 10px"
        size="small"
      >
        <el-option
          v-for="item in options2"
          :key="item.id"
          :label="item.value"
          :value="item.id"
        >
        </el-option>
      </el-select>
      <!-- <p style="float: left;margin: 0;margin-left:20px;">选择日期：</p> -->
      <el-date-picker
        v-model="MyZDDate"
        type="date"
        placeholder="选择日期"
        format="yyyy 年 MM 月 dd 日"
        value-format="yyyy-MM-dd"
        clearable
        style="width: 200px; margin-right: 10px"
        size="small"
      >
      </el-date-picker>
      <el-button type="primary" @click="getlist(1)" size="small"
        >查询</el-button
      >
      <el-button type="primary" @click="exportorder" size="small"
        >导出Excel</el-button
      >
    </div>
    <el-table :data="list" border style="width: 100%">
      <el-table-column prop="id" label="编号"></el-table-column>
      <!-- <el-table-column prop="input_id" label="客服姓名"></el-table-column> -->
      <el-table-column prop="pt_no" label="患者姓名"></el-table-column>
      <!-- <el-table-column label="电话" prop="pt_tel"></el-table-column> -->
      <el-table-column label="订单编号" prop="yf_order_no"></el-table-column>
      <el-table-column label="金额" prop="trans_amount">
        <template slot-scope="scope">
          <span>{{
            scope.row.trans_amount | number_format(scope.row.trans_amount)
          }}</span>
        </template>
      </el-table-column>
      <el-table-column label="创建时间" prop="create_time"></el-table-column>
      <el-table-column label="支付时间" prop="pay_time"></el-table-column>
      <el-table-column label="退款时间" prop="refund_time"></el-table-column>
      <el-table-column label="" prop="state" width="200">
        <template slot-scope="scope">
          <div class="opt doc">
            <p>
              <el-button
                size="mini"
                type="success"
                v-if="scope.row.refund_state == 1"
                @click="agree(scope.row)"
                >同意(欧西思)</el-button
              >
              <el-button
                size="mini"
                type="warning"
                v-if="scope.row.refund_state == 1"
                @click="refuse(scope.row, scope.$index)"
                >驳回</el-button
              >
            </p>
            <p>
              <el-button
                size="mini"
                type="success"
                v-if="scope.row.refund_state == 1"
                @click="agreenew(scope.row)"
                >同意(中辉荣盛)</el-button
              >
              <el-button size="mini" disabled v-if="scope.row.refund_state == 2"
                >已处理</el-button
              >
              <el-button
                size="mini"
                type="primary"
                @click="detail(scope.row, scope.$index)"
                >详情</el-button
              >
            </p>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <p class="paging">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="current"
        :page-sizes="pageSizes"
        :page-size="PageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="totalCount"
        :hide-on-single-page="value"
      >
      </el-pagination>
    </p>
    <el-table
      :data="tableData"
      border
      style="width: 100%"
      id="tableBox"
      v-show="false"
    >
      <el-table-column prop="id" label="编号"></el-table-column>
      <!-- <el-table-column prop="input_id" label="客服姓名"></el-table-column> -->
      <el-table-column prop="pt_no" label="患者姓名"></el-table-column>
      <!-- <el-table-column label="电话" prop="pt_tel"></el-table-column> -->
      <el-table-column label="订单编号" prop="yf_order_no"></el-table-column>
      <el-table-column label="金额" prop="trans_amount">
        <template slot-scope="scope">
          <span>{{
            scope.row.trans_amount | number_format(scope.row.trans_amount)
          }}</span>
        </template>
      </el-table-column>
      <el-table-column label="创建时间" prop="create_time"></el-table-column>
      <el-table-column label="支付时间" prop="pay_time"></el-table-column>
      <el-table-column label="退款时间" prop="refund_time"></el-table-column>
    </el-table>
    <el-dialog title="查看详情" :visible.sync="looktkdetail">
      <table class="tkdetail">
        <tr>
          <td class="title">订单号</td>
          <td>{{ tkdetaildes.out_trade_no }}</td>
        </tr>
        <!-- <tr>
          <td>退款次数</td>
          <td>{{tkdetaildes.refund_count}}</td>
        </tr> -->
        <tr>
          <td class="title">退款金额</td>
          <td>{{ filtermoney(tkdetaildes.refund_fee) }}</td>
        </tr>
        <tr>
          <td class="title">退回地址</td>
          <td>{{ tkdetaildes.refund_recv_accout_0 }}</td>
        </tr>
        <tr>
          <td class="title">退款成功时间</td>
          <td>{{ tkdetaildes.refund_success_time_0 }}</td>
        </tr>
        <tr>
          <td class="title">总金额</td>
          <td>{{ filtermoney(tkdetaildes.total_fee) }}</td>
        </tr>
        <tr>
          <td class="title">交易编号</td>
          <td>{{ tkdetaildes.transaction_id }}</td>
        </tr>
      </table>
    </el-dialog>
  </div>
</template>
<script>
const FileSaver = require("file-saver");
const XLSX = require("xlsx");
export default {
  inject: ["reload"],
  data() {
    return {
      list: [],
      current: 1, //默认显示第几页
      totalCount: 0, // 总条数，根据接口获取数据长度
      pageSizes: [10], // 个数选择器（可修改）
      PageSize: 10, // 默认每页显示的条数（可修改）
      value: false,
      MyZDDate: "",
      select2: 1,
      options2: [
        { id: 1, value: "未处理" },
        { id: 2, value: "已处理" },
      ],
      sid: "",
      serveid: "",
      loading: false,
      tableData: [],
      looktkdetail: false,
      tkdetaildes: {},
    };
  },
  mounted() {
    this.getlist(1);
    this.sid = sessionStorage.getItem("id");
    this.serveid = sessionStorage.getItem("servicer_id");
  },
  filters: {
    number_format: function (number) {
      var decimals = 2;
      var dec_point = ".";
      var thousands_sep = ",";
      number = (number + "").replace(/[^0-9+-Ee.]/g, "");
      var n = !isFinite(+number) ? 0 : +number,
        prec = !isFinite(+decimals) ? 2 : Math.abs(decimals),
        sep = typeof thousands_sep === "undefined" ? "," : thousands_sep,
        dec = typeof dec_point === "undefined" ? "." : dec_point,
        s = "",
        toFixedFix = function (n, prec) {
          var k = Math.pow(10, prec);
          return "" + Math.ceil(n * k) / k;
        };
      s = (prec ? toFixedFix(n, prec) : "" + Math.round(n)).split(".");
      var re = /(-?\d+)(\d{3})/;
      while (re.test(s[0])) {
        s[0] = s[0].replace(re, "$1" + sep + "$2");
      }
      if ((s[1] || "").length < prec) {
        s[1] = s[1] || "";
        s[1] += new Array(prec - s[1].length + 1).join("0");
      }
      return s.join(dec);
    },
  },
  methods: {
    handleSizeChange() {},
    handleCurrentChange(currentPage) {
      this.getlist(currentPage);
    },
    getNewDay(dateTemp, days) {
      var dateTemp = dateTemp.split("-");
      var nDate = new Date(dateTemp[1] + "-" + dateTemp[2] + "-" + dateTemp[0]); //转换为MM-DD-YYYY格式
      var millSeconds = Math.abs(nDate) + days * 24 * 60 * 60 * 1000;
      var rDate = new Date(millSeconds);
      var year = rDate.getFullYear();
      var month = rDate.getMonth() + 1;
      if (month < 10) month = "0" + month;
      var date = rDate.getDate();
      if (date < 10) date = "0" + date;
      return year + "-" + month + "-" + date;
    },
    getlist(page) {
      var date1 = "",
        date2 = "";
      if (this.MyZDDate != "") {
        date1 = this.MyZDDate + " 00:00:00";
        date2 = this.getNewDay(this.MyZDDate, 1) + " 00:00:00";
      }
      this.axios
        .get(
          "/yf/get_gu_pat_servorder_by_refund?page=" +
            page +
            "&size=" +
            this.PageSize +
            "&refund_state=" +
            this.select2 +
            "&datetime1=" +
            date1 +
            "&datetime2=" +
            date2
        )
        .then((res) => {
          if (res.data.code == 0) {
            this.list = res.data.result;
            this.totalCount = res.data.count;
          } else {
            this.list = [];
            this.totalCount = 0;
          }
        });
    },
    agreenew(row) {
      this.$confirm("此操作将退款给患者, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          var form = {
            yf_order_no: row.yf_order_no,
          };
          this.axios
            //新版中汇荣盛同意退款接口
            .post("/yf/pat_order_refund_zhonghui", this.qs.stringify(form))
            .then((res) => {
              console.log(res);
              if (
                res.data.result_code == "SUCCESS" &&
                res.data.return_code == "SUCCESS"
              ) {
                var form = {
                  refund_state: 2,
                  yf_order_no: row.yf_order_no,
                  uid: this.serveid,
                };
                this.axios
                  .post(
                    "/yf/update_gu_pat_servorder_pay_refund_state",
                    this.qs.stringify(form)
                  )
                  .then((res) => {
                    if (res.data.code == 0) {
                      this.$message({
                        message: "退款成功",
                        type: "success",
                      });
                      this.reload();
                    } else {
                      this.$message({
                        message: res.data.msg,
                        type: "error",
                      });
                    }
                  });
              } else {
                this.$message({
                  message: res.data.err_code_des,
                  type: "error",
                });
              }
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    agree(row) {
      this.$confirm("此操作将退款给患者, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          var form = {
            yf_order_no: row.yf_order_no,
          };
          this.axios
            //旧逸飞退款接口
            .post("/yf/pat_order_refund", this.qs.stringify(form))
            .then((res) => {
              console.log(res);
              if (
                res.data.result_code == "SUCCESS" &&
                res.data.return_code == "SUCCESS"
              ) {
                var form = {
                  refund_state: 2,
                  yf_order_no: row.yf_order_no,
                  uid: this.serveid,
                };
                this.axios
                  .post(
                    "/yf/update_gu_pat_servorder_pay_refund_state",
                    this.qs.stringify(form)
                  )
                  .then((res) => {
                    if (res.data.code == 0) {
                      this.$message({
                        message: "退款成功",
                        type: "success",
                      });
                      this.reload();
                    } else {
                      this.$message({
                        message: res.data.msg,
                        type: "error",
                      });
                    }
                  });
              } else {
                this.$message({
                  message: res.data.err_code_des,
                  type: "error",
                });
              }
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    refuse(row, index) {
      this.$confirm("确定拒绝此申请吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          var form = {
            refund_state: 3,
            yf_order_no: row.yf_order_no,
            uid: this.serveid,
          };
          this.axios
            .post(
              "/yf/update_gu_pat_servorder_pay_refund_state",
              this.qs.stringify(form)
            )
            .then((res) => {
              if (res.data.code == 0) {
                this.$message({
                  message: "已拒绝",
                  type: "success",
                });
                this.reload();
              } else {
                this.$message({
                  message: res.data.msg,
                  type: "error",
                });
              }
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    // 执行下载
    async exportorder() {
      await this.setData();
      this.exportOut();
    },
    setData: async function () {
      this.loading = true;
      this.arr = [];
      this.tableData = [];
      let num = Math.ceil(this.totalCount / this.PageSize);
      let m = "";
      let a = [];
      for (m = 1; m <= num; m++) {
        a = await this.funA(m);
        this.arr = this.arr.concat(a.data.result);
      }
      this.tableData = this.arr;
    },
    funA: async function (m) {
      var date1 = "",
        date2 = "";
      if (this.MyZDDate != "") {
        date1 = this.MyZDDate + " 00:00:00";
        date2 = this.getNewDay(this.MyZDDate, 1) + " 00:00:00";
      }
      return await this.axios.get(
        "/yf/get_gu_pat_servorder_by_refund?page=" +
          m +
          "&size=" +
          this.PageSize +
          "&refund_state=" +
          this.select2 +
          "&datetime1=" +
          date1 +
          "&datetime2=" +
          date2
      );
    },
    //下载表格
    exportOut() {
      var xlsxParam = { raw: true }; //转换成excel时，使用原始的格式
      var wb = XLSX.utils.table_to_book(
        document.querySelector("#tableBox"),
        xlsxParam
      );
      var wbout = XLSX.write(wb, {
        bookType: "xlsx",
        bookSST: true,
        type: "array",
      });
      try {
        FileSaver.saveAs(
          new Blob([wbout], { type: "application/octet-stream" }),
          new Date().getTime() + ".xlsx"
        );
      } catch (e) {
        if (typeof console !== "undefined") console.log(e, wbout);
      }
      this.loading = false;
      return wbout;
    },
    //查看退款详情
    detail(row, index) {
      this.gettkdetail(row);
    },
    gettkdetail(row) {
      this.axios
        .post(
          "http://192.168.11.253:8080/wpay/example/refundquery.php?",
          this.qs.stringify({ out_trade_no: row.yf_order_no })
        )
        .then((res) => {
          if (
            res.data.result_code == "SUCCESS" &&
            res.data.return_code == "SUCCESS"
          ) {
            this.looktkdetail = true;
            console.log(res.data);
            this.tkdetaildes = res.data;
          } else {
            this.$message({
              // message: res.data.err_code_des,
              message: "暂未开始退款",
              type: "error",
            });
          }
        });
    },
    filtermoney(val) {
      if (val) {
        return "￥" + val / 100;
      } else {
        return "";
      }
    },
  },
};
</script>
<style>
.add {
  width: 100%;
  height: 50px;
  text-align: left;
  float: left;
}
.Bdh {
  height: 50px;
  width: 100%;
  line-height: 40px;
}
.tkdetail tr {
  height: 30px;
  text-align: left;
}
.tkdetail .title {
  width: 130px;
}
</style>
